.active-menu {
  @apply bg-gray-200 border-l-4 border-green-400;
}

.webcam {
  position: absolute;
}

.canvas {
  position: relative;
}
